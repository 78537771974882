// 引入封装的第一层axios接口
import request from '@/utils/req.js'

/* banner获取 */
export const getPartyBanner = (params) => {
  return request({
    url: '/partys/partyBanner/getPartyBanner',
    method: 'get',
    params: {
      ...params
    }
  })
}
/* 获取目录 */
export const getContents = (params) => {
  return request({
    url: '/partys/partyContents/getContents',
    method: 'get',
    params: {
      ...params
    }
  })
}
/* 获取文章 */
export const getPartyResources = (params) => {
  return request({
    url: '/partys/partyResources/getPartyResources',
    method: 'get',
    params: {
      ...params
    }
  })
}
/* 获取文章详情 */
export const getDetail = (params) => {
  return request({
    url: '/partys/partyResources/getDetail',
    method: 'get',
    params: {
      ...params
    }
  })
}
/* 查询目录数据 */
export const getCountResources = (params) => {
  return request({
    url: '/partys/partyResources/getCountResources',
    method: 'get',
    params: {
      ...params
    }
  })
}
/* 学校党建列表 */
export const getDeptList = (params) => {
  return request({
    url: '/partys/blade-system/dept/page',
    method: 'get',
    params: {
      ...params
    }
  })
}
/* 党建详情 */
export const getDeptDetail = (params) => {
  return request({
    url: '/partys/partySchool/getPartySchool',
    method: 'get',
    params: {
      ...params
    }
  })
}
/* 获取学校党建内容 */
export const getPartyDetail = (params) => {
  return request({
    url: '/partys/partySchool/getDetail',
    method: 'get',
    params: {
      ...params
    }
  })
}

/* 获取用户心得 */
export const getUserExperience = (params) => {
  return request({
    url: '/partys/partyStudyExperience/getPartyStudyExperience',
    method: 'get',
    params: {
      ...params
    }
  })
}
/* 获取用户感悟 */
export const getUserPerception = (params) => {
  return request({
    url: '/partys/partyStudyPerception/getPartyStudyPerception',
    method: 'get',
    params: {
      ...params
    }
  })
}
/* 获取学习记录 */
export const getPartyIndividualRecordVo = (params) => {
  return request({
    url: '/partys/partyStudyRecord/getPartyIndividualRecordVo',
    method: 'get',
    params: {
      ...params
    }
  })
}
/* 获取学习心得 */
export const getPartyStudyExperience = (params) => {
  return request({
    url: '/partys/partyStudyExperience/getPartyStudyExperience',
    method: 'get',
    params: {
      ...params
    }
  })
}
/* 获取学习感悟 */
export const getPartyStudyPerception = (params) => {
  return request({
    url: '/partys/partyStudyPerception/getPartyStudyPerception',
    method: 'get',
    params: {
      ...params
    }
  })
}
/* 修改学习心得 */
export const updatePartyStudyExperience = (params) => {
  return request({
    url: '/partys/partyStudyExperience/updatePartyStudyExperience',
    method: 'put',
    data: {
      ...params
    }
  })
}

/* 修改学习心得 */
export const addPartyStudyExperience = (params) => {
  return request({
    url: '/partys/partyStudyExperience/savePartyStudyExperience',
    method: 'post',
    data: {
      ...params
    }
  })
}
/* 记录学习 */
export const recordUserStudy = (params) => {
  return request({
    url: '/partys/partyStudyRecord/savePartyUserApply',
    method: 'post',
    data: {
      ...params
    }
  })
}
/* 添加学习感悟 */
export const addLearningPerception = (params) => {
  return request({
    url: '/partys/partyStudyPerception/savePartyStudyPerception',
    method: 'post',
    data: {
      ...params
    }
  })
}
/* 删除学习感悟 */
export const deleteLearningPerception = (params) => {
  return request({
    url: '/partys/partyStudyPerception/deletePartyStudyPerception',
    method: 'get',
    params: {
      ...params
    }
  })
}

/* 党员申请  */
export const applyPartyMember = (params) => {
  return request({
    url: '/partys/partyUserApply/savePartyUserApply',
    method: 'post',
    data: {
      ...params
    }
  })
}
/* 关闭提示 */
export const turnOffPrompts = (params) => {
  return request({
    url: '/partys/blade-user/update-info',
    method: 'post',
    data: {
      ...params
    }
  })
}
/* 查询申请记录 */
export const applyPartyMemberRecord = (params) => {
  return request({
    url: '/partys/partyUserApply/getPartyUserApply',
    method: 'get',
    params: {
      ...params
    }
  })
}