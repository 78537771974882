
// 服务器IP
const server = 'https://www.wuhousmartedu.com/api';
//const server = 'http://localhost:7071';
// const server = 'http://172.16.10.63:7071/api';//办公室

//英姿太接口
const screenServer = 'https://whqjyy-school.wuhousmartedu.com';
// 测试服务器
//const server = 'http://139.155.73.40:8081';

/*
 * @Description: 单页面vue.config.js
 * @Date: 2021-11-04 11:02:45
 * @LastEditTime: 2021-11-08 16:33:31
 */
const path = require('path');

function resolve(dir) {
  return path.join(__dirname, dir);
}

// ====定义函数addStyleResource===
function addStyleResource(rule) {
  rule
    .use('style-resource')
    .loader('style-resources-loader')
    .options({
      patterns: [path.resolve(__dirname, './src/assets/style/var.styl')],
    });
}

module.exports = {
  // lintOnSave: process.env.NODE_ENV === 'development',
  devServer: {
    port: 18001,
    proxy: {
      '/api': {
        target: server,
        pathRewrite: {
          '^/api': '/',
        },
      },
      '/screen': {
        target: server,
      },
      '/dp': {
        target: screenServer,
        pathRewrite: {
          '^/dp': '/api',
        },
      },
      '/partys': {
        target: server,
        pathRewrite: {
          '^/partys': '/',
        },
      },
    },
  },
  publicPath: '/',
  chainWebpack: (config) => {
    config.plugin('html').tap((args) => {
      args[0].title = '武侯资源库';
      return args;
    });
    config.resolve.alias
      .set('@', resolve('./src'))
      .set('assets', resolve('./src/assets'))
      .set('constants', resolve('./src/constants'))
      .set('utils', resolve('./src/utils'));
    // 配置使用stylus全局变量
    const types = ['vue-modules', 'vue', 'normal-modules', 'normal'];
    types.forEach((type) =>
      addStyleResource(config.module.rule('stylus').oneOf(type))
    );
    // 配置处理svg
    const svgRule = config.module.rule('svg'); // 找到svg-loader
    svgRule.uses.clear(); // 清除已有的loader, 如果不这样做会添加在此loader之后
    svgRule.exclude.add(/node_modules/); // 正则匹配排除node_modules目录
    svgRule // 添加svg新的loader处理
      .test(/\.svg$/)
      .use('svg-sprite-loader')
      .loader('svg-sprite-loader')
      .options({
        symbolId: 'icon-[name]',
      });

    // 修改images loader 添加svg处理
    const imagesRule = config.module.rule('images');
    imagesRule.exclude.add(resolve('src/assets/icons'));
    config.module.rule('images').test(/\.(png|jpe?g|gif|svg)(\?.*)?$/);
    config.plugins.delete('named-chunks');
    config.module
      .rule('i18n')
      .resourceQuery(/blockType=i18n/)
      .type('javascript/auto')
      .use('i18n')
      .loader('@kazupon/vue-i18n-loader')
      .end();
  },

  pluginOptions: {
    i18n: {
      locale: 'zh',
      fallbackLocale: 'en',
      localeDir: 'locales',
      enableInSFC: true,
      includeLocales: true,
      enableBridge: false,
    },
  },
};
