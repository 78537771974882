// 引入封装的第一层axios接口
// import req from '@/utils/req.js'
import request from "@/utils/req";
// 通过账号密码登录 || 刷新token
/*export const loginByUsername_api = (params) => {
    return req.post('/blade-auth/oauth/token', params)
}*/

export const loginByUsername_api = (params) => {
    return request({
        url: '/api/blade-auth/oauth/token',
        method: 'post',
        params: {
            ...params
        },
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    })
}

export const getTouristId = () => {
    return request({
        url: '/api/blade-auth/oauth/getTouristId',
        method: 'get',
        params: {},
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    })
}

//获取个人信息
/*export const myInformation_api = (params) => {
    return req.get('/blade-student/student/myInformation', params)
}*/

export const myInformation_api = (params) => {
    return request({
        url: '/api/blade-student/student/myInformation',
        method: 'get',
        params: {
            ...params
        }
    })
}


//我的收藏
/*export const myCollection_api = (params) => {
    return req.get('/blade-student/student/myCollection', params)
}*/

export const myCollection_api = (params) => {
    return request({
        url: '/api/blade-student/student/myCollection',
        method: 'get',
        params: {
            ...params
        },
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    })
}

//收藏
/*export const saveCollection_api = (params) => {
    return req.post('/blade-student/student/saveCollection', params)
}*/

export const saveCollection_api = (params) => {
    return request({
        url: '/api/blade-student/student/saveCollection',
        method: 'post',
        params: {
            ...params
        },
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    })
}

//取消收藏
/*export const cancelCollection_api = (params) => {
    return req.post('/blade-student/student/cancelCollection', params)
}*/

export const cancelCollection_api = (params) => {
    return request({
        url: '/api/blade-student/student/cancelCollection',
        method: 'post',
        params: {
            ...params
        },
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    })
}

//我的报名课程
/*
export const mySubscribeCourse_api = (params) => {
    return req.get('/blade-student/student/mySubscribeCourse', params)
}
*/

export const mySubscribeCourse_api = (params) => {
    return request({
        url: '/api/blade-student/student/mySubscribeCourse',
        method: 'get',
        params: {
            ...params
        }
    })
}

//刷新token
export const refreshToken = (refresh_token, tenantId) => request({
  url: '/api/blade-auth/oauth/token',
  method: 'post',
  headers: {
    'Tenant-Id': tenantId,
    'Dept-Id': '',
    'Role-Id': ''
  },
  params: {
    tenantId,
    refresh_token,
    grant_type: "refresh_token",
    scope: "all",
  }
});

//统计菜单点击量
export const countVisitorsByMenu_api = (params) => {
    return request({
        url: '/api/regionIndex/countVisitorsByMenu',
        method: 'get',
        params: {
            ...params
        }
    })
}
