import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '../views/layou/Layou.vue';
import mineStudent from './mineStudent';
import mineTeacher from './mineTeacher';
import resourceLayout from '@/views/resourceCenter/layout/Layout';
import partyLayout from '@/views/party/layou/Layou';
// import {UPDATE_USER_INFO} from "@/constants/mutation-types";

import { setToken } from '@/utils/token.js';
import { sessionGet } from '@/utils/local';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'homepage',
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'home',
        component: () => import('@/views/8.30/Index'),
      },
      {
        path: '/zb',
        name: 'home',
        component: () => import('@/views/index/Index'),
      },
      // {
      //   path: '/8.30',
      //   name: '8.30',
      //   component: () => import('@/views/8.30/Index'),
      // },
      {
        path: '/More',
        name: '8.30More',
        component: () => import('@/views/8.30/More'),
      },
      {
        path: '/Detail',
        name: '8.30Detail',
        component: () => import('@/views/8.30/Detail'),
      },
      {
        path: '/Content',
        name: '8.30Detail',
        component: () => import('@/views/8.30/Content'),
      },
      {
        path: '/courseDetail',
        name: 'course',
        component: () => import('@/views/course/Course'),
      },
      {
        path: '/order',
        name: '课程详情',
        component: () => import('@/views/course/order'),
      },
      {
        path: '/orderNoSubscribe',
        name: '课程详情',
        component: () => import('@/views/course/orderNoSubscribe'),
      },
      {
        path: '/search',
        name: 'search',
        component: () => import('@/views/index/Search'),
      },
      {
        path: '/catalogue',
        name: 'catalogue',
        component: () => import('@/views/index/Catalogue'),
      },
      {
        path: '/liveSearch',
        name: 'liveSearch',
        component: () => import('@/views/index/liveSearch'),
      },
      /*{
      path: '/teacherProfile',
      name: 'teacherProfile',
      component: () => import('@/views/mine/teacher/TeacherProfile')
  },*/
      {
        path: '/404',
        name: '404',
        component: () => import('@/views/404/404'),
      },
    ],
  },
  {
    path: '/pusher',
    name: 'livePusher',
    component: () => import('@/views/pusher/pusher'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login'),
  },
  {
    path: '/record',
    name: 'homepage',
    component: Layout,
    children: [
      {
        path: '/record',
        name: '录播详情',
        component: () => import('@/views/course/record'),
      },
    ],
  },
  {
    path: '/liveStu/player',
    name: 'live',
    component: () => import('@/views/liveStu/player.vue'),
  },
  {
    path: '/liveStu/playerLubo',
    name: 'liveLubo',
    component: () => import('@/views/liveStu/playerLubo.vue'),
  },
  {
    path: '/liveStu/playerLuboMobile',
    name: 'liveLuboMobile',
    component: () => import('@/views/liveStu/playerLuboMobile.vue'),
  },
  {
    path: '/resource',
    name: 'homepage',
    component: resourceLayout,
    redirect: '/resource/region',
    children: [
      {
        path: '/resource/index',
        name: 'resourceIndex',
        meta: {
          belong: 'resource',
        },
        component: () => import('@/views/resourceCenter/index/Index'),
      },
      {
        path: '/resource/region',
        name: 'regionResource',
        meta: {
          belong: 'resource',
          keepAlive: true,
        },
        component: () => import('@/views/resourceCenter/region/index/index'),
      },
      /* {
       path: '/resource/region/details',
       name: 'regionDetails',
       component: () => import('@/views/resourceCenter/region/index/Details')
   },*/
      {
        path: '/resource/region/search',
        name: 'regionSearch',
        meta: {
          belong: 'resource',
        },
        component: () => import('@/views/resourceCenter/region/index/search'),
      },
      {
        path: '/resource/region/special',
        name: 'regionSpecial',
        meta: {
          belong: 'resource',
        },
        component: () => import('@/views/resourceCenter/region/index/special'),
      },
      {
        path: '/resource/school/index',
        name: 'schoolHome',
        meta: {
          belong: 'resource',
        },
        component: () => import('@/views/resourceCenter/school/index/index'),
      },
      {
        path: '/resource/details',
        name: 'resourceDetails',
        meta: {
          belong: 'resource',
        },
        component: () => import('@/views/resourceCenter/school/index/Details'),
      },
      /*{
      path: '/resource/search',
      name: 'schoolSearch',
      component: () => import('@/views/resourceCenter/school/index/search')
  },*/
      {
        path: '/resource/myCenter',
        name: 'myCenter',
        meta: {
          belong: 'resource',
        },
        component: () => import('@/views/resourceCenter/school/index/myCenter'),
      },
      {
        path: '/resource/share',
        name: 'shareResource',
        meta: {
          belong: 'resource',
        },
        component: () =>
          import('@/views/resourceCenter/school/index/shareResource'),
      },
      {
        path: '/resource/center/details',
        name: 'resourceCenterDetails',
        meta: {
          belong: 'resource',
        },
        component: () =>
          import('@/views/resourceCenter/school/index/resourceDetails'),
      },
      // 省级资源
      {
        path: '/resource/provincial',
        name: 'provincialResource',
        meta: {
          belong: 'resource',
        },
        component: () => import('@/views/resourceCenter/provincialLevel/Index'),
      },
      // 市级资源
      {
        path: '/resource/municipal',
        name: 'municipalResource',
        meta: {
          belong: 'resource',
        },
        component: () => import('@/views/resourceCenter/municipalLevel/Index'),
      },
      // 搜索结果页
      {
        path: '/resource/search',
        name: 'search',
        meta: {
          belong: 'resource',
        },
        component: () => import('@/views/resourceCenter/search/Search'),
      },
      // 劳动教育-学校经验展示
      {
        path: '/resource/schoolExperience',
        name: 'schoolExperience',
        component: () =>
          import(
            '@/views/resourceCenter/region/index/labourEducation/schoolExperience/Index'
          ),
      },
      // 劳动教育-学校经验展示-课程详情
      {
        path: '/resource/schoolExperienceDetail',
        name: 'schoolExperienceDetail',
        component: () =>
          import(
            '@/views/resourceCenter/region/index/labourEducation/schoolExperience/Detail'
          ),
      },
      // 劳动教育-学生实践助手
      {
        path: '/resource/practiceAssistant',
        name: 'practiceAssistant',
        component: () =>
          import(
            '@/views/resourceCenter/region/index/labourEducation/practiceAssistant/Index'
          ),
      },
      // 劳动教育-学生实践助手-课程详情
      {
        path: '/resource/practiceAssistantDetail',
        name: 'practiceAssistantDetail',
        component: () =>
          import(
            '@/views/resourceCenter/region/index/labourEducation/practiceAssistant/Detail'
          ),
      },
      // 劳动教育-学生实践助手-成果展示详情
      {
        path: '/resource/activityDetail',
        name: 'activityDetail',
        component: () =>
          import(
            '@/views/resourceCenter/region/index/labourEducation/practiceAssistant/ActivityDetail'
          ),
      },
      // 劳动教育-教师专享
      {
        path: '/resource/teachersOnly',
        name: 'teachersOnly',
        component: () =>
          import(
            '@/views/resourceCenter/region/index/labourEducation/teachersOnly/Index'
          ),
      },
      // 劳动教育-劳动基地
      {
        path: '/resource/base',
        name: 'base',
        component: () =>
          import(
            '@/views/resourceCenter/region/index/labourEducation/base/Base'
          ),
      },
      // 资源中心404
      {
        path: '/resource/404',
        name: 'resource404',
        meta: {
          belong: 'resource',
        },
        component: () => import('@/views/resourceCenter/404/404'),
      },
    ],
  },
  {
    path: '/onlineTeaching',
    name: 'homepage',
    component: resourceLayout,
    redirect: '/onlineTeaching/index',
    children: [
      {
        path: '/onlineTeaching/index',
        name: 'onlineTeaching',
        meta: {
          belong: 'onlineTeaching',
        },
        component: () => import('@/views/onlineTeaching/index'),
      },
      {
        path: '/onlineTeaching/detail',
        name: 'onlineTeaching',
        meta: {
          belong: 'onlineTeaching',
        },
        component: () => import('@/views/onlineTeaching/detail'),
      },
      // 资源中心404
      {
        path: '/onlineTeaching/404',
        name: 'onlineTeaching404',
        meta: {
          belong: 'onlineTeaching',
        },
        component: () => import('@/views/onlineTeaching/404/404'),
      },
    ],
  },
  {
    path: '/bmi',
    name: 'bmi',
    meta: {
      belong: 'bmi',
    },
    component: () => import('@/views/bmi/index'),
  },
  {
    path: '/statistics/screen',
    name: 'screen',
    meta: {
      belong: 'screen',
    },
    component: () => import('@/views/statistics/screen'),
  },
  {
    path: '/statistics/school',
    name: 'school',
    meta: {
      belong: 'school',
    },
    component: () => import('@/views/statistics/school'),
  },
  {
    path: '/statistics',
    name: 'statistics',
    meta: {
      belong: 'statistics',
    },
    component: () => import('@/views/statistics/screen'),
  },
  {
    path: '/noBmi',
    name: 'noBmi',
    component: () => import('@/views/bmi/noBmi'),
  },
  {
    path: '*', // 此处需特别注意置于最底部
    redirect: '/404',
  },
  {
    path: '/partyLogin',
    name: 'partyLogin',
    meta: {
      belong: 'partyLogin',
    },
    component: () => import('@/views/party/login/PartyLogin'),
  },
  /* 党建板块*/
  {
    path: '/party',
    name: 'party',
    component: partyLayout,
    redirect: '/party/index',
    children: [
      {
        path: '/party/index',
        name: 'partyIndex',
        meta: {
          belong: 'partyIndex',
        },
        component: () => import('@/views/party/index/index'),
      },
      {
        path: '/party/schoolParty',
        name: 'schoolParty',
        meta: {
          belong: 'schoolParty',
        },
        component: () => import('@/views/party/schoolParty/index'),
      },
      {
        path: '/party/schoolParty/detail',
        name: 'schoolPartyDetail',
        meta: {
          belong: 'schoolPartyDetail',
        },
        component: () => import('@/views/party/schoolParty/detail'),
      },
      {
        path: '/party/content',
        name: 'schoolPartyContent',
        meta: {
          belong: 'schoolPartyContent',
        },
        component: () => import('@/views/party/content'),
      },
      {
        path: '/party/politicalLife',
        name: 'politicalLife',
        meta: {
          belong: 'politicalLife',
        },
        component: () => import('@/views/party/politicalLife/index'),
      },
      {
        path: '/party/politicalLife/detail',
        name: 'politicalLifeDetail',
        meta: {
          belong: 'politicalLifeDetail',
        },
        component: () => import('@/views/party/politicalLife/detail'),
      },
      {
        path: '/party/exemplaryVanguard',
        name: 'exemplaryVanguard',
        meta: {
          belong: 'exemplaryVanguard',
        },
        component: () => import('@/views/party/exemplaryVanguard/index'),
      },
      {
        path: '/party/exemplaryVanguard/partyDetail',
        name: 'exemplaryVanguardPartyDetail',
        meta: {
          belong: 'exemplaryVanguardPartyDetail',
        },
        component: () => import('@/views/party/exemplaryVanguard/partyDetail'),
      },
      {
        path: '/party/exemplaryVanguard/schoolDetail',
        name: 'exemplaryVanguardSchoolDetail',
        meta: {
          belong: 'exemplaryVanguardSchoolDetail',
        },
        component: () => import('@/views/party/exemplaryVanguard/schoolDetail'),
      },
      {
        path: '/party/exemplaryVanguard/schoolList',
        name: 'exemplaryVanguardSchoolList',
        meta: {
          belong: 'exemplaryVanguardSchoolList',
        },
        component: () => import('@/views/party/exemplaryVanguard/schoolList'),
      },
      {
        path: '/party/exemplaryVanguard/partyList',
        name: 'exemplaryVanguardPartyList',
        meta: {
          belong: 'exemplaryVanguardPartyList',
        },
        component: () => import('@/views/party/exemplaryVanguard/partyList'),
      },
      {
        path: '/party/fineParty',
        name: 'fineParty',
        meta: {
          belong: 'fineParty',
        },
        component: () => import('@/views/party/fineParty/index'),
      },
      {
        path: '/party/learningPower',
        name: 'learningPower',
        meta: {
          belong: 'learningPower',
        },
        component: () => import('@/views/party/learningPower/index'),
      },
      {
        path: '/party/partyResources',
        name: 'partyResources',
        meta: {
          belong: 'partyResources',
        },
        component: () => import('@/views/party/partyResources/index'),
      },
      {
        path: '/party/partyTrends',
        name: 'partyTrends',
        meta: {
          belong: 'partyTrends',
        },
        component: () => import('@/views/party/partyTrends/index'),
      },
      {
        path: '/party/myCenter',
        name: 'myPartyCenter',
        meta: {
          belong: 'myPartyCenter',
        },
        component: () => import('@/views/party/myCenter/index'),
      },
      {
        path: '/party/indexMore',
        name: 'indexMore',
        meta: {
          belong: 'indexMore',
        },
        component: () => import('@/views/party/index/more'),
      },
      {
        path: '/party/trainingLearning',
        name: 'trainingLearning',
        meta: {
          belong: 'trainingLearning',
        },
        component: () => import('@/views/party/trainingLearning/index'),
      },
      {
        path: '/party/trainingDetail',
        name: 'trainingDetail',
        meta: {
          belong: 'trainingDetail',
        },
        component: () => import('@/views/party/trainingLearning/Detail'),
      },
    ],
  },
];

// 需要动态添加的路由
export const allRoutes = [mineStudent, mineTeacher];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  // routes: routes,
  routes: [...routes, mineStudent, mineTeacher],
});
/* 重写router方法 */
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

/* 外部链接直接进入网盘处理 */
router.beforeEach((to, from, next) => {
  if (to.query.token){
    to.query.ticket = to.query.token;
  }
  // console.log('to.path:'+to.path);
  if (to.name == 'myCenter') {
    if (to.query.ticket) {
      setToken(to.query.ticket).then((res) => {
        if (res) {
          next();
        }
      });
      return;
    } else {
      next();
    }
  } else if (to.name == 'bmi') {
    if (to.query.ticket) {
      setToken(to.query.ticket).then((res) => {
        if (res) {
          next();
        }
      });
      return;
    } else {
      alert('缺少ticket！');
    }
  } else if (to.name == 'practiceAssistant') {
    if (to.query.ticket) {
      setToken(to.query.ticket).then(() => {
        next({
          path: '/resource/practiceAssistant',
          query: {
            type: to.query.type ? to.query.type : 'laborWeek',
          },
        });
      });
      return false;
    }
    next({
      query: {
        type: 'laborWeek',
      },
    });
  } else if (to.name == 'myPartyCenter') {
    if (sessionGet('userInfo').role_name.indexOf('party') < 0) {
      next({
        path: '/party',
      });
    } else {
      next();
    }
  } else if (to.path == '/resource/region') {
    if (to.query.ticket) {
      setToken(to.query.ticket).then(() => {
        next();
      });
    } else {
      next();
    }
  } else if (to.path == '/party/index') {
    if (to.query.ticket) {
      setToken(to.query.ticket).then(() => {
        next();
      });
    } else {
      next();
    }
  } else if (to.path == '/Detail') {
    if (to.query.ticket) {
      setToken(to.query.ticket,to.query.courseId).then(() => {
        next();
      });
    } else {
      next();
    }
  } else {
    next();
  }

  if (to.name === '404') {
    if (from.meta && from.meta.belong === 'resource') {
      next({
        replace: false,
        name: 'resource404',
      });
    }
    next();
  }
});
export default router;
