<template>
  <div class="zhy">
    <Header class="header"></Header>
    <div class="router-view">
      <router-view :key="key"></router-view>
    </div>
    <Footer class="footer"></Footer>
  </div>
</template>

<script>
import Header from '@/views/layou/Header';
import Footer from '@/views/layou/Footer';

export default {
  name: 'Layout',
  components: {
    Footer,
    Header,
  },
  computed: {
    key() {
      return this.$route.path + Math.random();
    },
  },
};
</script>

<style scoped>
.zhy {
  /*background-color : #fff;*/
  /*padding-top: 80px;*/
  /*position: relative;*/

  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.router-view {
  /*margin-top: 2em;*/
  /*min-height: calc(100vh - 275px);*/
  flex-grow: 1;
  margin-top: 5rem;
  flex: 1;
  /*background-color : #f2f2f2;*/
}

a:link {
  color: #000000;
}

.header {
  position: fixed;
  z-index: 1000;
}

.footer {
}
</style>
