import mineLayout from "@/views/mine/teacher/mineLayout";
import Layout from "@/views/layou/Layou";

export default {
    path: '/mine',
    name: 'mine',
    component: Layout,
    children: [
        {
            path: '/mineTeacher',
            name: 'teacher',
            component: mineLayout,
            redirect: '/mineTeacher/course',
            children: [
                // {
                //     path: '/mineTeacher/index',
                //     name: '基础信息',
                //     component: () => import('@/views/mine/teacher/Index'),
                // },
                {
                    path: '/mineTeacher/course',
                    name: '课程管理',
                    component: () => import('@/views/mine/teacher/Course'),
                },
                {
                    path: '/mineTeacher/signUp',
                    name: '我的报名课程',
                    component: () => import('@/views/mine/student/signUp'),
                },
                {
                    path: '/mineTeacher/collect',
                    name: '收藏',
                    component: () => import('@/views/mine/student/collect.vue'),
                },
                {
                    path: '/mineTeacher/creditHours',
                    name: '学时管理',
                    component: () => import('@/views/mine/teacher/CreditHours.vue'),
                },

            ]
        },
        {
            path: '/mineStudent',
            name: 'student',
            component: mineLayout,
            redirect: '/mineStudent/index',
            children: [
                {
                    path: '/mineStudent/index',
                    name: '基础信息',
                    component: () => import('@/views/mine/index/userIndex.vue'),
                },
            ]
        },
    ],
}
