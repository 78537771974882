<template>
  <div class="header">
    <!--    <img :src="logo" alt="武侯智汇云">
        <div class="flex_row">
          <div class="m_r_30" v-show="this.$route.query.scene!='noSearch'">
            <el-input placeholder="请输入内容" v-model="inputValue" :clearable="true" class="input-with-select"
                      style="width: 500px;">
              <el-select v-model="resourceType" v-if="this.$route.query.scene !== 'school'" slot="prepend" placeholder="请选择"
                         style="width:110px;">
                <el-option :label="item.name" :value="item.id" v-for="(item,index) of catalogueList" :key="index">
                </el-option>
              </el-select>
              <el-button slot="append" icon="el-icon-search" @click="clickSearch"></el-button>
            </el-input>
          </div>
          <div class="flex_row">
            <router-link class="fs_14" :to="{path: '/resource/region/index'}">区域资源中心</router-link>
            &lt;!&ndash;    districtUser: 教育局     administrator/admin: 超管  &ndash;&gt;
            &lt;!&ndash; <router-link v-show="userInfo&&(userInfo.role_name !== 'administrator'&&userInfo.role_name!=='districtUser'&&userInfo.role_name!=='admin')"
                                 class="fs_14"
                                 :to="{path: '/resource/school/index',query:{scene:'school'}}"
                                 style="margin-left: 30px;">
                      {{ deptInfo ? deptInfo.deptName + '资源中心' : '校本资源中心' }}
                    </router-link>&ndash;&gt;
            <div class="line"></div>
            <router-link v-if="!userInfo" class="fs_14" :to="{path: '/login'}">
              登录
            </router-link>
            <template v-else>
              <el-dropdown class="pointer" trigger="click" placement="bottom" style="margin-left:30px;">
                <div class="flex_row" style="margin-top: 20px;">
                  <div class="m_r_30" style="line-height: 40px;">{{ userInfo.nick_name }}</div>
                  <img :src="userInfo.avatar?userInfo.avatar:defaultAvatar" width="40px" :alt="userInfo.nick_name"
                       style="border-radius: 50%;">
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="handleCenter"><i class="el-icon-user"></i>个人中心</el-dropdown-item>
                  <el-divider></el-divider>
                  <el-dropdown-item @click.native="handleOut"><i class="el-icon-switch-button"></i>退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </div>
        </div>-->
    <div class="header-content flex_row">
      <div class="flex_row fs_16 c_666">
        <img
          class="m_r_30"
          :src="logo"
          height="40px"
          alt=""
          style="margin-right: 110px; margin-top: 9px" />
        <!--      <div class="m_r_30" v-for="item of catalogueList" :key="item.id">{{ item.dictValue }}</div>-->
        <!-- <div class="m_r_30 pointer" :class="{'selected':selected === '0'}" @click="headerLink_click(0)">首页</div>
        <div class="m_r_30 pointer" @click="headerLink_click(1)">国家资源</div>
        <div class="m_r_30 pointer" :class="{'selected':selected === '2'}" @click="headerLink_click(2)">省级资源</div>
        <div class="m_r_30 pointer" :class="{'selected':selected === '3'}" @click="headerLink_click(3)">市级资源</div>
        <div class="m_r_30 pointer" :class="{'selected':selected === '4'}" @click="headerLink_click(4)">区级资源</div> -->
        <!-- <div class="m_r_30 pointer" :class="{'selected':selected === '5'}" @click="headerLink_click(6)">在线教学</div> -->
        <!-- <div class="m_r_30 pointer" @click="headerLink_click(5)">空中课堂</div> -->
        <!-- 屏蔽空中课堂（20220901） -->
      </div>
      <div class="flex_row">
        <div class="m_r_30 fs_14" style="color: #fff">
          资源数量：1056208
        </div>
        <div
          class="m_r_30"
          v-show="
            this.$route.query.scene != 'noSearch' &&
            selected != 5 &&
            this.$route.query.scene != 'onlineTeaching'
          ">
          <el-input
            placeholder="输入内容进行资源搜索"
            v-model="inputValue"
            :clearable="true"
            class="input-with-select"
            @keyup.enter.native="clickSearch">
            <el-button
              slot="append"
              icon="el-icon-search"
              style="
                background-color: #a2c8ca;
                color: #fff;
                border: 1px #a2c8ca solid;
              "
              @click="clickSearch">
              搜索
            </el-button>
          </el-input>
        </div>
        <!-- 屏蔽用户体系20220901-->
        <div class="flex_row">
          <router-link
            v-if="!userInfo"
            class="fs_14"
            style="color: #fff"
            :to="{ path: '/login' }">
            登录
          </router-link>
          <template v-else>
            <el-dropdown
              class="pointer"
              trigger="click"
              placement="bottom"
              style="margin-left: 30px">
              <div
                class="flex_row"
                style="margin-top: 10px">
                <img
                  :src="userInfo.avatar ? userInfo.avatar : defaultAvatar"
                  width="30px"
                  height="30px"
                  :alt="userInfo.nick_name"
                  style="border-radius: 50%; margin-top: 5px" />
                <div
                  class="m_r_30 m_lr_10"
                  style="line-height: 40px">
                  {{ userInfo.nick_name }}
                </div>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="handleCenter">
                  <i class="el-icon-user"></i>
                  个人中心
                </el-dropdown-item>
                <el-divider></el-divider>
                <el-dropdown-item @click.native="handleOut">
                  <i class="el-icon-switch-button"></i>
                  退出登录
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import logo from '@/assets/img/7d4dc520f6d621712f07b0d2a54332a 1@2x.png';
import { getHeaderClassify } from '@/api/resourceCenter/index';
import { countVisitorsByMenu_api } from '@/api/mine';
import { sessionClear, sessionGet } from 'utils/local';
import defaultAvatar from '@/assets/image/avatar.png';
import { sessionSet } from '../../../utils/local';

import femaleAdministrators from 'assets/image/avatar/femaleAdministrators.png';
import maleAdministrators from 'assets/image/avatar/maleAdministrators.png';
import femaleTeacher from 'assets/image/avatar/femaleTeacher.png';
import maleTeacher from 'assets/image/avatar/maleTeacher.png';
import femaleStudent from 'assets/image/avatar/femaleStudent.png';
import maleStudent from 'assets/image/avatar/maleStudent.png';
export default {
  name: 'Header',
  data: () => {
    return {
      logo,
      defaultAvatar,
      resourceType: '1',
      inputValue: '',
      // 搜索类别
      catalogueList: [
        {
          dictValue: '首页',
          id: '1',
        },
      ],
      userInfo: sessionGet('userInfo'),
      deptInfo: sessionGet('deptInfo'),
      selected: '',

      femaleAdministrators,
      maleAdministrators,
      femaleTeacher,
      maleTeacher,
      femaleStudent,
      maleStudent,
      window: window,
    };
  },
  watch: {
    '$route': {
      handler(newVal) {
        switch (newVal.name) {
          case 'resourceIndex':
            this.selected = '0';
            break;
          case 'regionResource':
            this.selected = '4';
            break;
          case 'provincialResource':
            this.selected = '2';
            break;
          case 'municipalResource':
            this.selected = '3';
            break;
          case 'onlineTeaching':
            this.selected = '5';
            break;
          case 'search':
            this.selected = newVal.query.from;
            this.inputValue = newVal.query.keyword;
            break;
          default:
            this.selected = '';
            break;
        }

        this.$forceUpdate();
      },
      immediate: true,
    },
  },
  created() {
    let user = sessionGet('userInfo');
    if (!user) return;

    // 1 男  2 女  '' 未知
    if (user.sex === 1) {
      if (user.role_name === 'student') {
        user.avatar = maleStudent;
      } else if (user.role_name === 'teacher') {
        user.avatar = maleTeacher;
      } else {
        user.avatar = maleAdministrators;
      }
    } else if (user.sex === 2) {
      if (user.role_name === 'student') {
        user.avatar = femaleStudent;
      } else if (user.role_name === 'teacher') {
        user.avatar = femaleTeacher;
      } else {
        user.avatar = femaleAdministrators;
      }
    } else {
      if (user.role_name === 'student') {
        user.avatar = maleStudent;
      } else if (user.role_name === 'teacher') {
        user.avatar = maleTeacher;
      } else {
        user.avatar = maleAdministrators;
      }
    }

    this.userInfo = user;
  },
  mounted() {
    this.getCatalogueList();
    setTimeout(() => {
      this.deptInfo = sessionGet('deptInfo');
    }, 10);
  },
  methods: {
    // 获取目录
    getCatalogueList() {
      let catalogueList = sessionGet('catalogueList');
      if (catalogueList)
        return (this.catalogueList = [
          {
            dictValue: '首页',
            id: '1',
          },
        ].concat(catalogueList));

      getHeaderClassify({}).then((res) => {
        this.catalogueList = [
          {
            dictValue: '首页',
            id: '1',
          },
        ].concat(res.data);

        sessionSet('catalogueList', res.data);
      });
    },
    // 搜索
    clickSearch() {
      if (this.window.location.href.includes('search') == false) {
        this.$router
          .push({
            path: '/resource/search',
            query: { from: this.selected, keyword: this.inputValue },
          })
          .catch(() => {});
      }
      this.$store.commit('setKey', this.inputValue);
      //sessionSet('searchParams', this.inputValue);

      /*//校本首页搜索
      if (this.$route.name == 'schoolHome') {
        this.$router.push({
          path: '/resource/search',
          query: {
            "keyword": this.inputValue,
            "scene": 'school'
          }
        });
      }
      //校本搜索页搜索
      if (this.$route.name == 'schoolSearch') {
        this.$router.replace({
          path: '/resource/search',
          query: {
            "scene": 'school',
            "keyword": this.inputValue,
            "timestamp": new Date().getTime()
          }
        });
      }
      //区域资源搜索页搜索
      if (this.$route.name == 'regionSearch') {
        this.$router.replace({
          path: '/resource/region/search',
          query: {
            "id": this.resourceType,
            "keyword": this.inputValue,
            "timestamp": new Date().getTime()
          }
        });
      }
      //区域资源首页跳转搜索页、专题页搜索
      if (this.$route.name == 'regionHome' || this.$route.name == 'regionSpecial') {
        this.$router.push({
          path: '/resource/region/search',
          query: {
            "id": this.resourceType,
            "keyword": this.inputValue
          }
        });
      }
      //详情页搜索
      if (this.$route.name == 'regionDetails' || this.$route.name == 'resourceDetails') {
        if (this.$route.query.scene == 'school') {
          this.$router.push({
            path: '/resource/search',
            query: {
              "keyword": this.inputValue,
              "scene": 'school'
            }
          });
        } else {
          this.$router.push({
            path: '/resource/region/search',
            query: {
              "id": this.resourceType,
              "keyword": this.inputValue
            }
          });
        }
      }*/
    },
    // 退出登录
    handleOut() {
      this.$confirm('是否确认退出登录', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        sessionClear('userInfo');
        sessionClear('tk');
        sessionClear('userId');
        sessionClear('deptInfo');
        clearInterval(sessionGet('timer'));
        this.userInfo = null;
        this.deptInfo = null;
        // this.$router.go(0)
        this.$router.replace({
          path: '/resource/index',
        });
        this.$forceUpdate();
      });
    },
    toSchoolResource() {
      if (!this.userInfo) {
        this.$message.warning('请登录后进行操作');
        return this.$router.push({
          path: '/login',
        });
      }
      this.$router.push({
        path: '/resource/school/index',
      });
    },
    handleCenter() {
      this.$router.push({
        path: '/resource/myCenter',
        query: { scene: 'noSearch' },
      });
    },

    headerLink_click(target) {
      //接口统计数据
      let type = '';
      switch (target) {
        case 0:
          type = 1;
          break;
        case 2:
          type = 2;
          break;
        case 3:
          type = 3;
          break;
        case 4:
          type = 4;
          break;
        case 6:
          type = 5;
          break;
      }
      if (type != '') {
        //数据统计
        countVisitorsByMenu_api({ type: type });
      }
      switch (target) {
        case 0:
          this.$router.push({ path: '/resource/index' }).catch(() => {});
          break;
        case 1:
          // location.href = 'https://basic.smartedu.cn/'
          window.open('https://basic.smartedu.cn/', '_blank');
          break;
        case 2:
          this.$router.push({ path: '/resource/provincial' }).catch(() => {});
          break;
        case 3:
          this.$router.push({ path: '/resource/municipal' }).catch(() => {});
          break;
        case 4:
          this.$router.push({ path: '/resource/region' }).catch(() => {});
          break;
        case 5:
          window.open('https://www.wuhousmartedu.com/');
          break;
        case 6:
          this.$router.push({ path: '/onlineTeaching/index' }).catch(() => {});
          break;
      }
    },
  },
};
</script>
<style scoped>
.header {
  width: 100%;
  height: 60px;
  /* background: #f6f6f6; */
  background: url('../../../assets/img/Group 1632.png');
  position: relative;
  line-height: 60px;
  text-align: center;
  box-shadow: 0 1px 10px 0 #b6b6b675;
  justify-content: space-around;
}

.header-content {
  width: 90%;
  min-width: 1350px;
  justify-content: space-between;
  margin: 0 auto;
}

.el-divider--horizontal {
  margin: 10px 0;
}

a:hover {
  color: #1f62ec;
}

.selected {
  color: #12c294;
}

::deep .el-input__inner {
  border-radius: 20px 0 0 20px;
  border-right: 0;
  border-color: #12c294;
}

::deep .el-input-group__append {
  border-radius: 0 20px 20px 0;
  background-color: #12c294;
  color: #fff;
  border-color: #12c294;
}

::deep .el-input__inner:focus {
  border-color: #12c294;
}
</style>
