<template>
  <div class="container">
    <el-carousel height="300px">
      <div v-if="banner.length > 0">
        <el-carousel-item
          v-for="item in banner"
          :key="item.id">
          <img
            :src="item.url"
            style="
              height: 100%;
              width: 100%;
              object-fit: cover;
              cursor: pointer;
            "
            @click="toDetail(item)" />
        </el-carousel-item>
      </div>
      <img
        :src="defaultBanner"
        style="height: 100%; width: 100%; object-fit: cover"
        v-else />
    </el-carousel>
    <div class="login-box">
      <div class="flex_row portrait">
        <!-- <img class="avatar " :src="userInfo.avatar" alt="">
        <div>
          {{ userInfo.nick_name }}
        </div> -->
        <el-button
          class="l_b"
          @click="handleOut"
          v-if="userInfo">
          退出登录
        </el-button>
        <el-button
          class="l_b"
          @click="toProfile"
          v-else>
          登录
        </el-button>
      </div>
    </div>
    <div class="box">
      <div class="content flex_row items">
        <!-- <div
          v-for="(item, index) in menus"
          v-show="
            userInfo.role_name.indexOf('party') < 0
              ? item.name != '个人中心'
              : item.name
          "
          :key="index"
          @click="$router.push({ path: item.path, query: { id: item.id } })"
          :class="defaultSelect == item.name ? 'active' : ''">
          {{ item.name }}
        </div> -->
        <div
          v-for="(item, index) in menus"
          v-show="
            userInfo.role_name.indexOf('party') < 0
              ? item.name != '个人中心'
              : item.name
          "
          :key="index"
          @click="navigation_click(item)"
          :class="defaultSelect == item.name ? 'active' : ''">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sessionGet, sessionSet, sessionClear } from '@/utils/local';
import { mapGetters } from 'vuex';
import femaleAdministrators from 'assets/image/avatar/femaleAdministrators.png';
import maleAdministrators from 'assets/image/avatar/maleAdministrators.png';
import femaleTeacher from 'assets/image/avatar/femaleTeacher.png';
import maleTeacher from 'assets/image/avatar/maleTeacher.png';
import femaleStudent from 'assets/image/avatar/femaleStudent.png';
import maleStudent from 'assets/image/avatar/maleStudent.png';
import {
  getPartyBanner,
  getContents,
  applyPartyMember,
  applyPartyMemberRecord,
} from '@/api/party/index';
import defaultBanner from '@/assets/image/banner.png';
export default {
  name: 'Header',
  data: () => {
    return {
      banner: [],
      defaultBanner,
      searchValue: '',
      userInfo: '',
      defaultSelect: '首页',
      menus: [],
      roleJson: {
        administrator: 'teacher',
        admin: 'teacher',
        teacher: 'teacher',
        districtUser: 'teacher',
        xiaoManangAdmin: 'teacher',
      },
      femaleAdministrators,
      maleAdministrators,
      femaleTeacher,
      maleTeacher,
      femaleStudent,
      maleStudent,
    };
  },
  computed: {
    ...mapGetters(['isLogin']),
  },
  watch: {
    $route: {
      //导航选中状态
      handler: function (val) {
        switch (val.name) {
          case 'partyIndex':
            this.defaultSelect = '首页';
            break;
          case 'schoolParty':
            this.defaultSelect = '学校党建';
            break;
          case 'schoolPartyDetail':
            this.defaultSelect = '学校党建';
            break;
          case 'exemplaryVanguardPartyDetail':
            if (val.query.from == 2) {
              this.defaultSelect = '模范先锋';
            } else if (val.query.from == 1) {
              this.defaultSelect = '学校党建';
            }
            break;
          case 'politicalLife':
            this.defaultSelect = '政治生活';
            break;
          case 'schoolPartyContent': //详情页跳转板块区分
            if (val.query.from == 3 || val.query.from == 4) {
              this.defaultSelect = '政治生活';
            } else if (val.query.from == 1) {
              this.defaultSelect = '党建动态';
            } else if (val.query.from == 2) {
              this.defaultSelect = '党建资源';
            } else if (val.query.from == 5) {
              this.defaultSelect = '学校党建';
            } else if (val.query.from == 6) {
              this.defaultSelect = '党风廉政';
            } else if (val.query.from == 8) {
              this.defaultSelect = '学习强国';
            }
            break;
          case 'politicalLifeDetail':
            this.defaultSelect = '政治生活';
            break;
          case 'exemplaryVanguard':
            this.defaultSelect = '模范先锋';
            break;
          case 'fineParty':
            this.defaultSelect = '党风廉政';
            break;
          case 'learningPower':
            this.defaultSelect = '学习强国';
            break;
          case 'trainingLearning':
            this.defaultSelect = '学习培训';
            break;
          case 'partyResources':
            this.defaultSelect = '党建资源';
            break;
          case 'partyTrends':
            this.defaultSelect = '党建动态';
            break;
          case 'myPartyCenter':
            this.defaultSelect = '个人中心';
            break;
        }
      },
      immediate: true,
    },
  },
  async created() {
    //获取菜单
    const { data } = await getContents();
    sessionSet('SystemMenus', data);
    data.map((item) => {
      switch (item.name) {
        case '首页':
          item.path = '/party';
          break;
        case '党建动态':
          item.path = '/party/partyTrends';
          break;
        case '党建资源':
          item.path = '/party/partyResources';
          break;
        case '学习培训':
          item.path = '/party/trainingLearning';
          break;
        case '政治生活':
          item.path = '/party/politicalLife';
          break;
        case '党风廉政':
          item.path = '/party/fineParty';
          break;
        case '模范先锋':
          item.path = '/party/exemplaryVanguard';
          break;
        case '学习强国':
          item.path = '/party/learningPower';
          break;
      }
      this.menus.push(item);
    });
    this.menus.push(
      {
        name: '学校党建',
        path: '/party/schoolParty',
      },
      {
        name: '个人中心',
        path: '/party/myCenter',
      }
    );
    //获取系统banner
    getPartyBanner({
      type: 1,
      status: 1,
    }).then((res) => {
      this.banner = res.data;
    });
    let user = sessionGet('userInfo');
    if (!user) {
      sessionClear('userInfo');
      sessionClear('tk');
      sessionClear('userId');
      clearInterval(sessionGet('timer'));
      this.userInfo = undefined;
      this.$forceUpdate();
      this.$router.push('/partylogin');
      this.$store.commit('updateLogin', false);
    }

    // 1 男  2 女  '' 未知
    if (user.sex === 1) {
      if (user.role_name === 'student') {
        user.avatar = maleStudent;
      } else if (user.role_name === 'teacher') {
        user.avatar = maleTeacher;
      } else {
        user.avatar = maleAdministrators;
      }
    } else if (user.sex === 2) {
      if (user.role_name === 'student') {
        user.avatar = femaleStudent;
      } else if (user.role_name === 'teacher') {
        user.avatar = femaleTeacher;
      } else {
        user.avatar = femaleAdministrators;
      }
    } else {
      if (user.role_name === 'student') {
        user.avatar = maleStudent;
      } else if (user.role_name === 'teacher') {
        user.avatar = maleTeacher;
      } else {
        user.avatar = maleAdministrators;
      }
    }
    this.userInfo = user;
  },
  methods: {
    // toPage() {
    //   //添加样式
    //   this.$refs.items.childNodes.forEach(item => {
    //     console.log(item)
    //     // if (item.dataset.index == i) {
    //     //   item.classList.add('active');
    //     // } else {
    //     //   item.classList.remove('active');
    //     // }
    //   })
    // },
    //个人中心
    toProfile() {
      if (!this.userInfo) {
        this.$router.push({
          path: '/partyLogin',
        });
      } else {
        if (this.roleJson[this.userInfo.role_name] === 'teacher') {
          this.$router.push('/mineTeacher');
        } else {
          this.$router.push('/mine');
        }
      }
    },
    // 退出登录
    handleOut() {
      this.$confirm('是否确认退出登录', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        sessionClear('userInfo');
        sessionClear('isTips');
        sessionClear('tk');
        sessionClear('userId');
        clearInterval(sessionGet('timer'));
        this.userInfo = undefined;
        this.$forceUpdate();
        this.$router.push('/partyLogin');
        this.$store.commit('updateLogin', false);
      });
    },
    //banner详情
    toDetail(item) {
      if (item.link) {
        window.open(item.link);
      }
      if (item.resourcesId > 0) {
        this.$router.push({
          path: '/party/content',
          query: {
            cid: item.resourcesId,
          },
        });
      }
    },
    async navigation_click(data) {
      // 判断是否是党员、管理员
      let haveStatus = 0;
      let role = [
        'partyBuildingMembers',
        'partyBuildingSchoolAdministrator',
        'partyBuildingAdministrator',
      ];
      role.forEach((item) => {
        if (this.userInfo.role_name.indexOf(item) >= 0) {
          haveStatus = 1;
        }
      });
      // 是否已经申请
      await applyPartyMemberRecord({
        userId: this.userInfo.user_id,
        isExamine: 2,
      }).then((res) => {
        if (res.data.length > 0) {
          haveStatus = 2;
        }
      });
      if (data.name == '学习培训' && haveStatus !== 1) {
        if (haveStatus == 2) {
          this.$alert(
            '该账号已申请党员身份，请等待审核通过后再进行操作！',
            '提示',
            {
              confirmButtonText: '确定',
            }
          );
        } else {
          this.$confirm('您目前的账号还未获得党员身份，无法查看该部分内容。如您是党员，请提交申请并等待获得党员身份后再查看', '提示', {
            confirmButtonText: '申请',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            let params = {
              isExamine: 2,
              userId: this.userInfo.user_id,
            };
            applyPartyMember(params)
              .then(() => {
                this.$message.success('已提交申请');
              })
              .catch(() => {
                this.$message.error('申请失败，请稍后重试');
              });
          });
        }
        return;
      }
      this.$router.push({ path: data.path, query: { id: data.id } });
    },
  },
};
</script>

<style
  scoped
  lang="scss">
  ::v-deep.el-carousel--horizontal{
    overflow: hidden;
  }
.login-box {
  position: absolute;
  height: 20%;
  z-index: 999;
  right: 20%;
  top: 48%;
}

.container {
  height: 350px;
  width: 100%;
  /* background-color: aqua; */
  position: relative;
  z-index: 998;
}

.banner {
  border: 10px #000 solid;
  height: 100%;
}

.el-button:focus,
.el-button:hover {
  color: #fff;
}

.portrait {
  /* width: 260px; */
  justify-content: space-between;
  color: #000;
  justify-items: center;
  align-items: center;
  font-size: 18px;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -65px;
  padding: 0 10px;

  img {
    width: 75px;
  }

  > div {
    padding: 20px;
  }
}

.box {
  background-color: #af0000;
  background-size: 100% 100%;
  color: #fff;
  height: 5rem;
  width: 100%;
  box-shadow: 0 2px 10px rgba(200, 200, 200, 0.3);
  position: absolute;
  bottom: 0;
}

.l_b {
  width: 120px;
  height: 40px;
  background: linear-gradient(180deg, #ffbe73, #dd2622 100%);
  border-radius: 4px;
  color: #fff;
}

.content {
  width: 65%;
  height: 100%;
  margin: auto;
  line-height: 5rem;
  /* min-width: 120rem; */
  font-size: 1.6rem;

  > div {
    opacity: 0.8;
  }

  justify-content: space-between;
}

.items {
  > div {
    cursor: pointer;
  }

  .active {
    color: #fff;
    opacity: 1;
    position: relative;
  }
  .active:after {
    content: '▲';
    position: absolute;
    top: 21px;
    font-size: 10px;
    transform: translateX(-50%);
    left: 50%;
  }
}
</style>
