// 引入封装的第一层axios接口
import request from '@/utils/req';

// 获取本期亮点
export const getCurrentHotIssue = (params) => {
  return request({
    url: '/api/blade-resources/data/getCurrentHotIssue',
    method: 'get',
    params: {
      ...params,
    },
  });
};
// 获取热门资源
export const getHotResources = (params) => {
  return request({
    url: '/api/blade-resources/data/getHotResources',
    method: 'get',
    params: {
      ...params,
    },
  });
};
//往期精彩数据
export const getPastWonderfulData = (params) => {
  return request({
    url: '/api/blade-resources/data/getPastWonderfulData',
    method: 'get',
    params: {
      ...params,
    },
  });
};
//查询首页分类
export const getResourcesClassifyPage = (params) => {
  return request({
    url: '/api/blade-resources/classify/getResourcesClassifyPage',
    method: 'get',
    params: {
      ...params,
    },
  });
};
//查询详情
export const getDetail = (params) => {
  return request({
    url: '/api/blade-resources/data/getDetail',
    method: 'get',
    params: {
      ...params,
    },
  });
};
//点赞
export const addResourcesLike = (params) => {
  return request({
    url: '/api/schoolIndex/addResourcesLike',
    method: 'get',
    params: {
      ...params,
    },
  });
};
//根据目录查询资源
export const getDataPage = (params) => {
  return request({
    url: '/api/blade-resources/data/getDataPage',
    method: 'get',
    params: {
      ...params,
    },
  });
};
//收藏
export const addResourcesCollect = (params) => {
  return request({
    url: '/api/schoolIndex/addResourcesCollect',
    method: 'get',
    params: {
      ...params,
    },
  });
};
//获取文章详情
export const getContentDetail = (params) => {
  return request({
    url: '/api/blade-desk/notice/detail',
    method: 'get',
    params: {
      ...params,
    },
  });
};
//文章评论
export const review = (params) => {
  return request({
    url: '/api/resourcesReview/review',
    method: 'put',
    params: {
      ...params,
    },
  });
};
//评论详情
export const reviewDetail = (params) => {
  return request({
    url: '/api/resourcesReview/manage/detail',
    method: 'get',
    params: {
      ...params,
    },
  });
};
//评论分页
export const reviewPage = (params) => {
  return request({
    url: '/api/resourcesReview/page',
    method: 'get',
    params: {
      ...params,
    },
  });
};
//获取banner
export const getBanner = (params) => {
  return request({
    url: '/api/BannerService/banner/selectBytype',
    method: 'get',
    params: {
      ...params,
    },
  });
};
//在线观看记录
export const addViewer = (params) => {
  return request({
    url: '/api/blade-resources/data/addViewer',
    method: 'get',
    params: {
      ...params,
    },
  });
};
