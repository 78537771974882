// 引入封装的第一层axios接口
import request from "@/utils/req";

export const getCourseList = (params) => {
    return request({
        url: '/api/blade-resources/telecast/homeList',
        method: 'get',
        params: {
            ...params
        }
    })
}

export const addCourse = (params) => {
    return request({
        url: '/api/blade-resources/telecast/saveTelecastCourse',
        method: 'post',
        data: {
            ...params
        },
        headers: {
            "Content-Type": "application/json;charset=UTF-8;"
        }
    })
}

export const updateCourse = (params) => {
    return request({
        url: '/api/blade-resources/telecast/updateTelecastCourse',
        method: 'post',
        data: {
            ...params
        },
        headers: {
            "Content-Type": "application/json;charset=UTF-8;"
        }
    })
}

export const deleteCourse = (params) => {
    return request({
        url: '/api/blade-resources/telecast/removeTelecastCourse',
        method: 'get',
        params: {
            ...params
        }
    })
}

export const getCoursePeople = (params) => {
    return request({
        url: '/api/blade-resources/subscribe/subscribeNameList',
        method: 'get',
        params: {
            ...params
        }
    })
}


export const getTeacherInfo = (params) => {
    return request({
        url: '/api/blade-student/student/myInformation',
        method: 'get',
        params: {
            ...params
        }
    })
}

export const getResourcesByUserIdPage = (params) => {
    return request({
        url: '/api/blade-resources/teacher/getResourcesByUserIdPage',
        method: 'get',
        params: {
            ...params
        }
    })
}

// 获取学时统计
export const getCreditHoursStatistics = (params) => {
    return request({
        url: '/api/blade-resources/telecast/getCountTeacherHour',
        method: 'get',
        params: {
            ...params
        }
    })
}

// 获取课程学时数据
export const getCourseCreditHours = (params) => {
    return request({
        url: '/api/blade-resources/telecast/getTeacherHourPage',
        method: 'get',
        params: {
            ...params
        }
    })
}
