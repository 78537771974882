<template>
  <div
    id="app"
    v-if="$route.meta.belong != 'bmi'">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import { refreshToken } from '@/api/mine';
import { UPDATE_USER_INFO } from '@/constants/mutation-types';
import { sessionGet, sessionSet } from '@/utils/local.js';
import { getResourcesClassifyPage } from '@/api/8.30';
export default {
  name: 'App',
  components: {},
  async mounted() {
    //查询八点半分类
    const { data: classifyPage } = await getResourcesClassifyPage({
      name: '八点半学院',
    });
    let classify = classifyPage.records[0].contentsList;
    sessionSet('classify', classify);
    //页面刷新重新获取token
    if (sessionGet('userInfo')) {
      let res = sessionGet('userInfo');
      //刷新token
      refreshToken(res.refresh_token, res.tenant_id).then((res) => {
        this.saveInfo(res);
        //检测token是否过期
        let loginTime = new Date().getTime() + res.expires_in * 1000;
        let timer = setInterval(() => {
          //当过期时间小于十分钟时刷新token
          if (loginTime - new Date().getTime() < 60 * 1000 * 10) {
            refreshToken(res.refresh_token, res.tenant_id).then((res) => {
              this.saveInfo(res);
              loginTime = new Date().getTime() + res.expires_in * 1000;
            });
          }
        }, 30000);
        sessionSet('timer', timer);
      });
    }
  },
  methods: {

    saveInfo(info) {
      this.$store.commit(UPDATE_USER_INFO, {
        userId: info.user_id,
        userName: info.nick_name,
        userAvatar: info.avatar,
        deptId: info.dept_id,
        roleName: info.role_name,
      });
      let token = {
        access_token: info.access_token,
        refresh_token: info.refresh_token,
        token_type: info.token_type,
      };
      sessionSet('tk', token);
      sessionSet('userInfo', info);
      sessionSet('userId', info.user_id);
    },
  },
};
</script>

<style>
* {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /*height: 100vh;*/
  background: linear-gradient(180deg, #fefffa, #eef8f4 100%);
  /* background-color: #f9f9f9; */
  /* background-image: url("./assets/image/shading.png"); */
}

a:link,
a:visited,
a:hover,
a:active {
  color: #000000;
}

/* 横屏 */
@media all and (orientation: landscape) {
  #app {
    min-width: 1500px;
  }
}

.el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px;
  height: 10px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
  opacity: 0.5;
}

.el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 10px;
  height: 10px;
  background: #ffffff;
  border-radius: 50%;
  opacity: 1;
}
</style>
