<template>
  <div class="foot">
    <div class="w80c flex_row">
      <div class="link flex_col w30">
        <div class="footerTitle">友情链接</div>
        <a
          href="https://admin.wuhousmartedu.com/#/login?type=1"
          target="_blank"
          class="FooterLink"
          style="margin-top: 23px"
          v-show="userInfo && userInfo.role_name !== 'student'">
          智汇云后台管理系统
        </a>
        <a
          href="https://www.smartedu.cn/"
          target="_blank"
          class="FooterLink"
          style="margin-top: 23px">
          国家智慧教育公共服务平台
        </a>
        <a
          href="http://www.sc.smartedu.cn/"
          target="_blank"
          class="FooterLink"
          style="margin-top: 16px">
          四川智慧教育平台
        </a>
        <a
          href="https://educloud.cdedu.com/#/tourist"
          target="_blank"
          class="FooterLink"
          style="margin-top: 16px">
          成都智慧教育云平台
        </a>
      </div>
      <div class="link flex_col w30">
        <div class="footerTitle">技术支持</div>
        <div
          class="flex_row aligin"
          style="margin-top: 23px">
          <span class="allText">四川纵横六合科技股份有限公司</span>
        </div>
        <div
          class="flex_row aligin"
          style="margin-top: 16px">
          <span class="allText">400-900-9799</span>
        </div>
        <div
          class="flex_row aligin"
          style="margin-top: 16px">
          <span class="allText">服务时间：工作日8:00 ~ 20:00</span>
        </div>
      </div>
      <div
        class="link flex_col w30"
        style="padding-left: 60px">
        <div class="footerTitle">关于我们</div>
        <div
          class="flex_row aligin"
          style="margin-top: 23px">
          <span class="allText">成都市武侯区黉门街29号</span>
        </div>
        <div
          class="flex_row aligin"
          style="margin-top: 16px">
          <span class="allText">成都市武侯区教育局</span>
        </div>
        <div
          class="flex_row aligin"
          style="margin-top: 16px">
          <span class="allText">whzhjyy@163.com</span>
        </div>
      </div>
      <div
        class="flex_col"
        style="text-align: right; padding-left: 55px">
        <img
          src="@/assets/image/newWhcode.jpg"
          style="width: 130px; border-radius: 6px" />
        <div style="text-align: center; margin-top: 8px; color: #0f4444">
<!--          武侯智汇云-->
        </div>
      </div>
    </div>
    <div class="copyRight">
      Copyright © 成都市武侯区教育局版权所有：&nbsp;
      <a
        href="https://beian.miit.gov.cn"
        target="_blank">
        蜀ICP备2021030208号-3
      </a>
    </div>
  </div>
</template>

<script>
import { sessionGet } from 'utils/local';

export default {
  name: 'Footer',
  data() {
    return {
      userInfo: sessionGet('userInfo'),
    };
  },
};
</script>

<style scoped>
.foot {
  width: 100%;
  text-align: center;
  background-color: #d8e5e1;
  padding: 20px 0;
}

.w80c {
  width: 1435px;
  margin: 0 auto;
}

.footerTitle {
  font-size: 16px;
  color: #0f4444;
  font-weight: 550;
}

.link {
  text-align: left;
  justify-content: space-evenly;
}

.FooterLink {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #0f4444;
  cursor: pointer;
}

.we {
  font-size: 16px;
  color: #ecf0f3;
  font-family: PingFang SC-Medium, PingFang SC;
  text-align: center;
}

.w30 {
  width: 28%;
  padding-left: 15px;
}

.copyRight {
  text-align: center;
  font-size: 12px;
  font-family: PingFang SC-Medium, PingFang SC;
  color: rgba(19, 69, 68, 0.5);
  margin-top: 10px;
}

.copyRight a {
  color: rgba(19, 69, 68, 0.5);
}

.allText {
  font-size: 14px;
  color: #0f4444;
  font-family: PingFang SC-Medium, PingFang SC;
}

.iconImg {
  height: 20px;
  margin: 0 8px 0 0;
}

.aligin {
  align-items: center;
}
</style>
