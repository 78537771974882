<template>
  <div class="mine">
    <!-- <LeftMenu/> -->
    <div class="main">
      <div style="margin-bottom: 20px;color: #a8a8a8;font-size: 14px;">首页 > 个人中心</div>
      <Header/>
      <div>
        <el-menu
            class="tabbar"
            background-color="#fff"
            text-color="#333"
            active-text-color="#fff"
            router
            :default-active="this.$route.path"
        >
          <el-menu-item
              :index="item.path"
              v-for="(item, i) in routes"
              :key="i"
          >
            <span slot="title" class="fs_16">{{ item.name }}</span>
          </el-menu-item>
        </el-menu>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
// import LeftMenu from "@/views/mine/teacher/leftMenu";
import {sessionGet} from "@/utils/local";
import Header from "@/views/mine/teacher/header";

export default {
  name: "index",
  components: {
    // LeftMenu,
    Header
  },
  data: () => {
    return {
      routes: [],
      roleJson: {
        administrator: 'teacher',
        admin: 'teacher',
        teacher: 'teacher',
        districtUser: 'teacher',
        xiaoManangAdmin: 'teacher'
      }
    }
  },
  methods: {
    // 动态路由
    createRouter() {
      let userInfo = sessionGet('userInfo')
      let identity = this.userInfo.role_name
      const mineRoutes = this.$router.options.routes
      mineRoutes.forEach(mineRoutesItem => {
        if (mineRoutesItem.name === 'mine') {
          mineRoutesItem.children.forEach(item => {
            if (item.name === this.roleJson[identity]) {
              this.routes = item.children.slice(0, 3)
              if (userInfo.role_name === 'teacher') {
                this.routes = item.children
              }
            }
          })
        }
      })
    },
  },
  created() {
    this.userInfo = sessionGet('userInfo')
    if (!this.userInfo) {
      this.$message.warning('请登录')
      return this.$router.push('/login')
    }
    this.createRouter()
  }
}
</script>

<style scoped>
* {
  margin: 0 auto;
  padding: 0;
}

.mine {
  width: 90%;
  margin: 0 auto;
  display: flex;
  padding: 40px 0;
  box-sizing: border-box;
}

.main {
  /*width       : 70%;*/
  width: 124rem;
  min-height: 600px;
  /* margin-left : 1.2em; */
}

.tabbar {
  width: 100%;
  display: flex;
  color: #000;
}

::deep .el-menu {
  border: 0 !important;
}

::deep .el-menu-item {
  padding: 0 !important;
  margin: 0 10px;
  height: 46px;
}

::deep .el-menu-item.is-active {
  color: #000 !important;
  background-color: #fff !important;
  border-bottom: 2px #3888f7 solid;
  font-weight: bold;
}

::deep .el-menu-item:hover {
  background-color: #fff !important;
}
</style>
