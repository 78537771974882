export const set = (key, val) => {
  return localStorage.setItem(key, JSON.stringify(val))
}

export const get = (key) => {
  if (localStorage.getItem(key))
    return JSON.parse(localStorage.getItem(key))
  else return null
}

export const clear = (key) => {
  if (key) localStorage.removeItem(key)
  else localStorage.clear()
}

export const sessionSet = (key, val) => {
  return sessionStorage.setItem(key, JSON.stringify(val))
}

export const sessionSetItem = (key, childKey, val) => {
  if (sessionStorage.getItem(key)) {
    let data = JSON.parse(sessionStorage.getItem(key))
    data[childKey] = val
    return sessionStorage.setItem(key, JSON.stringify(data))
  } else {
    return null
  }
}

export const sessionGet = (key) => {
  if (sessionStorage.getItem(key))
    return JSON.parse(sessionStorage.getItem(key))
  else return null
}

export const sessionClear = (key) => {
  if (key) sessionStorage.removeItem(key)
  else sessionStorage.clear()
}
