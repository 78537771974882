/*
 * @Description: vuex-mutations
 * @Date: 2021-11-04 11:02:45
 * @LastEditTime: 2022-02-15 18:06:33
 */
import * as playerMutationTypes from '@/constants/mutation-types';
import * as pusherMutationTypes from '@/constants/pusher/mutation-types';

export default {
  /*
   * 直播拉流
   * */
  // 设置sdkAppId
  [playerMutationTypes.SET_SDK_APP_ID](state, value) {
    state.sdkAppId = value;
  },
  // 设置userSig
  [playerMutationTypes.SET_USER_SIG](state, value) {
    state.userSig = value;
  },
  // 设置播放域名
  [playerMutationTypes.SET_PLAYER_DOMAIN](state, value) {
    state.playerDomain = value;
  },
  // 更新直播间ID
  [playerMutationTypes.SET_ROOM_ID](state, value) {
    state.roomId = value;
  },
  // 更新直播间昵称
  [playerMutationTypes.SET_ROOM_NAME](state, value) {
    state.roomName = value;
  },
  // 更新用户信息
  [playerMutationTypes.UPDATE_USER_INFO](state, data) {
    state.userInfo = data;
  },
  // 设置主播用户ID
  [playerMutationTypes.SET_ANCHOR_USER_ID](state, data) {
    state.anchorUserId = data;
  },
  // 更新直播间阶段
  [playerMutationTypes.UPDATE_LIVE_STAGE](state, value) {
    state.liveStage = value;
  },
  // 更新播放线路
  [playerMutationTypes.UPDATE_LINE_TYPE](state, value) {
    state.lineType = value;
  },
  // 更新流播放状态
  [playerMutationTypes.UPDATE_PLAY_STATE](state, value) {
    state.playState = value;
  },
  // 更新使用的摄像头设备
  [playerMutationTypes.UPDATE_ACTIVE_CAMERA](state, device) {
    state.activeCamera = device;
  },
  // 更新使用的麦克风设备
  [playerMutationTypes.UPDATE_ACTIVE_MICROPHONE](state, device) {
    state.activeMicrophone = device;
  },
  // 更新使用的扬声器设备
  [playerMutationTypes.UPDATE_ACTIVE_SPEAKER](state, device) {
    state.activeSpeaker = device;
  },
  // 更新是否设置本地流镜像
  [playerMutationTypes.UPDATE_SET_MIRROR](state, value) {
    state.isSetMirror = value;
  },
  // 更新是否设置本地流镜像
  [playerMutationTypes.UPDATE_OPEN_BEAUTY](state, value) {
    state.isOpenBeauty = value;
  },
  // 更新视频参数
  [playerMutationTypes.UPDATE_VIDEO_PROFILE](state, data) {
    state.videoProfile = data;
  },
  // 更新美颜参数
  [playerMutationTypes.UPDATE_BEAUTY_PARAM](state, data) {
    state.beautyParam = data;
  },
  // 更新是否录制直播
  [playerMutationTypes.UPDATE_RECORD_LIVE](state, value) {
    state.isRecordLive = value;
  },
  // 更新视频mute状态
  [playerMutationTypes.UPDATE_VIDEO_STATE](state, value) {
    state.isVideoMuted = value;
  },
  // 更新音频mute状态
  [playerMutationTypes.UPDATE_AUDIO_STATE](state, value) {
    state.isAudioMuted = value;
  },
  // 更新音量大小
  [playerMutationTypes.UPDATE_AUDIO_LEVEL](state, value) {
    state.audioLevel = value;
  },
  // 更新上行网络等级
  [playerMutationTypes.UPDATE_UPLINK_NETWORK_LEVEL](state, value) {
    state.uplinkQualityLevel = value;
  },
  // 更新横竖屏
  [playerMutationTypes.UPDATE_LAYOUT](state, value) {
    state.layout = value;
  },
  // 设置当前浏览器是否支持 webRTC
  [playerMutationTypes.SET_IS_SUPPORT_WEBRTC](state, value) {
    state.isSupportWebRTC = value;
  },

  /*
   * 直播推流
   * */
  // 更新直播间ID
  [pusherMutationTypes.SET_ROOM_ID](state, value) {
    state.roomId = value;
  },
  // 更新直播间昵称
  [pusherMutationTypes.UPDATE_ROOM_NAME](state, value) {
    state.roomName = value;
  },
  // 更新用户信息
  [pusherMutationTypes.UPDATE_USER_INFO](state, data) {
    state.userInfo = data;
  },
  // 设置sdkAppId信息
  [pusherMutationTypes.SET_APP_INFO](state, data) {
    state.appInfo = data;
  },
  // 更新直播间阶段
  [pusherMutationTypes.UPDATE_LIVE_STAGE](state, value) {
    state.liveStage = value;
  },
  // 更新是否在屏幕分享中
  [pusherMutationTypes.UPDATE_IS_SCREEN_SHARING](state, value) {
    state.isScreenSharing = value;
  },
  // 更新使用的摄像头设备
  [pusherMutationTypes.UPDATE_ACTIVE_CAMERA](state, device) {
    state.activeCamera = device;
  },
  // 更新使用的麦克风设备
  [pusherMutationTypes.UPDATE_ACTIVE_MICROPHONE](state, device) {
    state.activeMicrophone = device;
  },
  // 更新使用的扬声器设备
  [pusherMutationTypes.UPDATE_ACTIVE_SPEAKER](state, device) {
    state.activeSpeaker = device;
  },
  // 更新是否设置本地流镜像
  [pusherMutationTypes.UPDATE_SET_MIRROR](state, value) {
    state.isSetMirror = value;
  },
  // 更新是否设置本地流镜像
  [pusherMutationTypes.UPDATE_OPEN_BEAUTY](state, value) {
    state.isOpenBeauty = value;
  },
  // 更新视频参数
  [pusherMutationTypes.UPDATE_VIDEO_PROFILE](state, data) {
    state.videoProfile = data;
  },
  // 更新美颜参数
  [pusherMutationTypes.UPDATE_BEAUTY_PARAM](state, data) {
    state.beautyParam = data;
  },
  // 更新是否录制直播
  [pusherMutationTypes.UPDATE_RECORD_LIVE](state, value) {
    state.isRecordLive = value;
  },
  // 更新视频mute状态
  [pusherMutationTypes.UPDATE_VIDEO_STATE](state, value) {
    state.isVideoMuted = value;
  },
  // 更新音频mute状态
  [pusherMutationTypes.UPDATE_AUDIO_STATE](state, value) {
    state.isAudioMuted = value;
  },
  // 更新音量大小
  [pusherMutationTypes.UPDATE_AUDIO_LEVEL](state, value) {
    state.audioLevel = value;
  },
  // 更新上行网络等级
  [pusherMutationTypes.UPDATE_UPLINK_NETWORK_LEVEL](state, value) {
    state.uplinkQualityLevel = value;
  },
  // 更新直播间人员列表
  [pusherMutationTypes.UPDATE_MEMBER_LIST](state, value) {
    state.memberList = value;
  },
  // // 更新登录状态
  // [pusherMutationTypes.UPDATE_LOGIN](state, bool) {
  //     state.isLogin = bool
  // },
  // // 更新用户信息
  // updateUserInfo(state, userInfo) {
  //     state.userInfo = userInfo
  // },
  // 更新登录状态
  UPDATE_LOGIN(state, bool) {
    state.isLogin = bool;
  },
  //更新搜索条件
  setKey(state, key) {
    state.searchKey = key;
  },
};
