import axios from 'axios';
// import qs from 'qs'

import { Message } from 'element-ui';
// 引入路由
import router from '@/router';
// import store from '@/store'

// 工具js
import { sessionGet, clear, sessionClear } from '@/utils/local.js';
import jsCookie from 'js-cookie';

//http request拦截
axios.interceptors.request.use(
  (config) => {
    // let token = sessionGet('tk')?sessionGet('tk'):(jsCookie.get('tk')!=undefined?jsCookie.get('tk'):null)
    let token = sessionGet('tk') ? sessionGet('tk') : jsCookie.get('tk');
    // let token = sessionGet('tk')
    config.headers['Authorization'] = 'Basic c2FiZXI6c2FiZXJfc2VjcmV0';
    config.headers['Tenant-Id'] = '501654';
    if (token) {
      config.headers['Blade-Auth'] =
        token.token_type + ' ' + token.access_token;
    }

    //headers中配置text请求
    if (config.text === true) {
      config.headers['Content-Type'] = 'text/plain';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// // 响应拦截器响应后，统一做一些事情
axios.interceptors.response.use(
  (res) => {
    if (res.data.code === 401) {
      Message.error('您的权限已失效，请重新登录');
      clear();
      return router.push('/login');
    }
    return res.data;
  },
  (error) => {
    let status = error.response.status;

    if (status === 500) {
      return Message.error('服务器异常，请稍后重试！');
    }
    // 失败的回调  无效的token(路由守卫)
    if (status === 401) {
      Message.error('您的权限已失效，请重新登录');
      sessionClear();
      clear();
      if (error.response.request.responseURL.indexOf('/party/') >= 0) {
        router.push('/partyLogin');
        return;
      }
      router.push('/login');
      // store.commit('refresh_token', false)
      // store.commit('resetState')
      return;
    }
    if (status === 404) {
      Message.error('网页丢失了');
      router.push('/error404');
    }
    if (status !== 200) {
      Message.error(error.response.data.msg);
    }
  }
);
export default axios;
