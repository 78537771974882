<template>
  <div class="zhy">
    <Header class="header"></Header>
    <div class="router-view">
      <router-view></router-view>
    </div>
    <Footer class="footer"></Footer>
  </div>
</template>

<script>
import Header from "@/views/party/layou/Header";
import Footer from "@/views/party/layou/Footer";

export default {
  name: 'Layout',
  components: {
    Footer,
    Header,
  }
}
</script>

<style scoped>
  /deep/.el-button{
    border: none;
  }
.zhy {
  background-color : #fffaf5;
  /*padding-top: 80px;*/
  /*position: relative;*/

  display        : flex;
  flex-direction : column;
  min-height     : 100vh;
  min-width: 1200px;
}

.router-view {
  /*margin-top: 2em;*/
  /*min-height: calc(100vh - 275px);*/
  flex-grow        : 1;
  width: 65%;
  margin: 0 auto;
  /* margin-top       : 320px; */
  flex             : 1;
  /*background-color : #f2f2f2;*/
}

a:link {
  color : #000000;
}

.header {
  /* position : fixed; */
  z-index  : 1000;
}

.footer {

}
</style>
