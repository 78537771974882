<template>
  <div class="head">
    <div class="user-info">
      <div>
        <div class="img-box" style="margin-left: 30px;">
          <!--          <el-image
                        :src="userInfo.avatar"
                        fit="cover"
                        alt="加载失败"
                    ></el-image>-->
          <img class="avatar" :src="userInfo.avatar" alt="">
        </div>
      </div>
      <div style="font-size: 14px;line-height: 24px;margin-left: 30px;">
        <div class="w50 flex_row name-box">
          <div class="m_r_10" style="font-size: 22px;font-weight:bold">{{ infoList.name }}</div>
          <div class="gender-box m_r_10" :class="{'male-color':infoList.sex == 1 }" v-show="infoList.sex">
            <img :src="infoList.sex == 2 ? femaleImg : maleImg" alt="" style="margin-top: 5px">
          </div>
          <span class="identity p_lr_10">{{ infoList.roleName }}</span>
        </div>
        <div v-show="infoList.phone" class="w50">手机：{{ infoList.phone }}</div>
        <div class="w50">学校：{{ infoList.deptName }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import avatarGirl from '@/assets/img/avatar_01.gif'
import {getTeacherInfo} from "@/api/mine/teacher";
import maleImg from 'assets/image/male.png'
import femaleImg from 'assets/image/female.png'

import femaleAdministrators from 'assets/image/avatar/femaleAdministrators.png'
import maleAdministrators from 'assets/image/avatar/maleAdministrators.png'
import femaleTeacher from 'assets/image/avatar/femaleTeacher.png'
import maleTeacher from 'assets/image/avatar/maleTeacher.png'
import femaleStudent from 'assets/image/avatar/femaleStudent.png'
import maleStudent from 'assets/image/avatar/maleStudent.png'
import {sessionGet} from "../../../utils/local";

export default {
  name: "leftMenu",
  data() {
    return {
      avatarGirl,
      infoList: {},
      loading: false,
      userInfo: {},
      maleImg,
      femaleImg,
      femaleAdministrators,
      maleAdministrators,
      femaleTeacher,
      maleTeacher,
      femaleStudent,
      maleStudent
    }
  },
  mounted() {
    this.getInfo()

    let user = sessionGet('userInfo')
    if (!user) return

    // 1 男  2 女  '' 未知
    if (user.sex === 1) {
      if (user.role_name === 'student') {
        user.avatar = maleStudent
      } else if (user.role_name === 'teacher') {
        user.avatar = maleTeacher
      } else {
        user.avatar = maleAdministrators
      }
    } else if (user.sex === 2) {
      if (user.role_name === 'student') {
        user.avatar = femaleStudent
      } else if (user.role_name === 'teacher') {
        user.avatar = femaleTeacher
      } else {
        user.avatar = femaleAdministrators
      }
    } else {
      if (user.role_name === 'student') {
        user.avatar = maleStudent
      } else if (user.role_name === 'teacher') {
        user.avatar = maleTeacher
      } else {
        user.avatar = maleAdministrators
      }
    }

    this.userInfo = user
  },
  methods: {
    async getInfo() {
      this.loading = true
      await getTeacherInfo({}).then(res => {
        this.infoList = res.data
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.head {
  width: 100%;
  padding: 20px;
  background: #fff;
  box-shadow: 0px 0px 10px #eee;
  margin-bottom: 20px;
}

.user-info {
  display: flex;
}

.img-box {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  overflow: hidden;
  background-color: #eee;
}

.gender-box {
  background-color: #F96855;
  color: #fff;
  padding-left: 5px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
}

.male-color {
  background-color: #409eff;
}

.name-box {
  height: 26px;
  line-height: 26px;
}

.identity {
  background: #3888F7;
  color: #fff;
  border-radius: 2px;
}

.avatar {
  width: 70px;
  height: 70px;
}
</style>
