<template>
  <!--  <div class="box">
      <div class="content">
        <div class="fs_24 m_lr_10 fw_bold" style="margin-top:1rem;">
          空中课堂
        </div>
        <div class="option-box">
          <div class="option-item c_fff pointer" @click="toIndex">首页</div>
          <div class="option-item" style="border-left: 1px solid #fff;height:22px;margin-top:10px;"></div>
          <div class="flex_row">
            <div class="flex_row pointer" @click="toProfile">
              <div class="c_fff" v-if="!userInfo">登录</div>
              <div v-else class="flex">
                <div class="c_fff" style="margin-left: 0.8em;">{{ userInfo.nick_name }}</div>
                <div class="option-item c_fff" style="margin-left: 0.8em;">个人中心</div>
              </div>
            </div>
            <div class="c_fff pointer" style="margin-left: 1rem;" v-if="userInfo" @click="handleOut">退出登录</div>
          </div>
        </div>
      </div>
    </div>-->
  <div class="box">
    <div class="content flex_row">
      <span
        class="fs_24 fw_bold"
        style="color: #fff">
        八点半学苑
        <!-- 空中课堂 -->
      </span>

      <span class="flex_row fs_14">
        <div class="flex_row">
          <div
            class="m_r_30"
            v-show="
              this.$route.query.scene != 'noSearch' &&
              //selected != 5 &&
              this.$route.query.scene != 'onlineTeaching'
            "></div>
        </div>
        <span
          class="m_r_30 pointer"
          @click="toIndex">
          首页
        </span>
        <span class="line m_r_30"></span>
        <el-dropdown v-if="userInfo">
          <div class="flex_row pointer">
            <span class="c_fff">
              {{ userInfo.nick_name }}
            </span>
            <img
              class="avatar"
              :src="userInfo.avatar"
              alt="" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="toProfile">
              <i class="el-icon-user"></i>
              个人中心
            </el-dropdown-item>
            <el-dropdown-item @click.native="handleOut">
              <i class="el-icon-switch-button"></i>
              退出登录
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <span
          class="pointer"
          v-else
          @click="toProfile">
          登录
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { sessionGet, sessionClear,sessionSet } from '@/utils/local';
import { mapGetters } from 'vuex';
import { getTouristId } from '@/api/mine';

import femaleAdministrators from 'assets/image/avatar/femaleAdministrators.png';
import maleAdministrators from 'assets/image/avatar/maleAdministrators.png';
import femaleTeacher from 'assets/image/avatar/femaleTeacher.png';
import maleTeacher from 'assets/image/avatar/maleTeacher.png';
import femaleStudent from 'assets/image/avatar/femaleStudent.png';
import maleStudent from 'assets/image/avatar/maleStudent.png';

export default {
  name: 'Header',
  data: () => {
    return {
      searchValue: '',
      userInfo: '',
      roleJson: {
        administrator: 'teacher',
        admin: 'teacher',
        teacher: 'teacher',
        districtUser: 'teacher',
        xiaoManangAdmin: 'teacher',
      },
      femaleAdministrators,
      maleAdministrators,
      femaleTeacher,
      maleTeacher,
      femaleStudent,
      maleStudent,
    };
  },
  computed: {
    ...mapGetters(['isLogin']),
  },
  created() {
    let user = sessionGet('userInfo');
    //console.log("touristId:::"+sessionGet('touristId'));
    if (!user){
      getTouristId().then(
          (res) => {
            if (res.code == 200) {
              //sessionSet('touristId', res.data);
            }
          }
      );
      return;
    }

    // 1 男  2 女  '' 未知
    if (user.sex === 1) {
      if (user.role_name === 'student') {
        user.avatar = maleStudent;
      } else if (user.role_name === 'teacher') {
        user.avatar = maleTeacher;
      } else {
        user.avatar = maleAdministrators;
      }
    } else if (user.sex === 2) {
      if (user.role_name === 'student') {
        user.avatar = femaleStudent;
      } else if (user.role_name === 'teacher') {
        user.avatar = femaleTeacher;
      } else {
        user.avatar = femaleAdministrators;
      }
    } else {
      if (user.role_name === 'student') {
        user.avatar = maleStudent;
      } else if (user.role_name === 'teacher') {
        user.avatar = maleTeacher;
      } else {
        user.avatar = maleAdministrators;
      }
    }

    this.userInfo = user;
  },
  methods: {
    toProfile() {
      if (!this.userInfo) {
        this.$router.push({ path: '/login' });
      } else {
        if (this.roleJson[this.userInfo.role_name] === 'teacher') {
          this.$router.push('/mineTeacher');
        } else {
          this.$router.push('/mine');
        }
      }
    },
    // 退出登录
    handleOut() {
      this.$confirm('是否确认退出登录', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        sessionClear('userInfo');
        sessionClear('tk');
        sessionClear('userId');
        clearInterval(sessionGet('timer'));
        this.userInfo = undefined;
        this.$forceUpdate();
        this.$router.replace('/index');
        this.$store.commit('updateLogin', false);
      });
    },
    toIndex() {
      if (this.$route.path === '/index') {
        this.$router.go(0);
      } else {
        this.$router.push({ path: '/index' });
      }
    },
  },
};
</script>

<style scoped>
.box {
  background: url('../../assets/img/Group 1632.png') #3b8989;
  background-size: 100% 100%;
  color: #fff;
  height: 5rem;
  width: 100%;
  box-shadow: 0 2px 10px rgba(200, 200, 200, 0.3);
}

.content {
  width: 80%;
  height: 100%;
  margin: auto;
  line-height: 5rem;
  min-width: 120rem;
  justify-content: space-between;
}

.line {
  width: 0.1rem;
  height: 1.4rem;
  background-color: #9f9f9f;
  margin-top: 1.8rem;
}

.avatar {
  width: 30px;
  height: 30px;
  margin: 10px 30px 10px 10px;
}
</style>
