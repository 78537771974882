// 引入封装的第一层axios接口
import request from "utils/req";

// 头部目录
export const getHeaderClassify = (params) => {
  return request({
    url: '/api/blade-system/dict/dictionary?code=home_classify',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 资源目录
export const getResourceClassify = (params) => {
  return request({
    url: '/api/regionIndex/getContents',
    method: 'get',
    params: {
      ...params
    }
  })
}
// 资源目录树
export const getResourceClassifyTree = (params) => {
  return request({
    url: '/api/regionIndex/getSubClassifyTree',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 获取下一级资源目录
export const getSubClassifyById = (params) => {
  return request({
    url: '/api/regionIndex/getSubClassifyById',
    method: 'get',
    params: {
      ...params
    }
  })
}
//区级资源获取下一级目录
export const getRegionalSubClassifyById = (params) => {
  return request({
    url: '/api/regionIndex/getSubClassifyByIdOrderAsc',
    method: 'get',
    params: {
      ...params
    }
  })
}
// 分页获取资源
export const getResourceList = (params) => {
  return request({
    url: '/api/regionIndex/getRegionIndexPage',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 省级资源左目录树
export const getLeftClassifyTree = (params) => {
  return request({
    url: '/api/regionIndex/getSubClassifyTreeByContentId',
    method: 'get',
    params: {
      ...params
    }
  })
}


// 首页二级目录
export const getIndexCatalogueList = (params) => {
  return request({
    url: '/api/regionIndex/getContents',
    method: 'get',
    params: {
      ...params
    }
  })
}



// 首页二级目录
export const getPhoneEncrypt = (params) => {
  return request({
    url: 'api/blade-user/getPhoneEncryptById',
    method: 'get',
    params: {
      ...params
    }
  })
}