/* 免登陆操作 */
import {
  sessionSet,
  sessionGet
} from "@/utils/local.js";
import {
  loginByUsername_api
} from '@/api/mine'
import axios from 'axios';
function saveInfo(info) {
  // this.$store.commit(UPDATE_USER_INFO, {
  //   userId: info.user_id,
  //   userName: info.nick_name,
  //   userAvatar: info.avatar,
  //   deptId: info.dept_id,
  //   roleName: info.role_name
  // })
  let token = {
    access_token: info.access_token,
    refresh_token: info.refresh_token,
    token_type: info.token_type,
  };
  sessionSet("tk", token);
  sessionSet("userInfo", info);
  sessionSet("userId", info.user_id);
}
export function setToken(ticket,id) {
  return new Promise((resolve) => {
    if (sessionGet('tk')) {
      resolve(true);
    } else {
      //三方登录
      axios.get('https://www.wuhousmartedu.com/api/blade-user/getUserByPhone', {
        //axios.get('http://localhost:18001/api/blade-user/getUserByPhone', {
        params: {
          ticket: ticket,
        }
      }).then(res => {
        console.error('res:'+res);
        if (res.code == 200) {
          let info = res.data;
          let datas = {
            tenantId: info.tenantId,
            username: info.account,
            password: info.password,
            scope: "all",
            type: "account",
            types: 2
          }
          /* 登录操作*/
          loginByUsername_api(datas).then(res => {
            if (res.error_code === 400) {
              alert('账号错误')
              resolve(false);
            }
            if (res.error) {
              alert(res.error_description)
              resolve(false);
            }
            if (res.access_token) {
              saveInfo(res);
              resolve(true);
            }
          })

        } else {
          // console.log('location.hash.indexOf("Detail")::'+location.hash.indexOf('Detail'));
          // console.log('id::'+id);
          if (location.hash.indexOf('bmi') > 0) {
            window.location.href = 'https://www.wuhousmartedu.com/#/noBmi';
            window.location.reload();
          } else if (location.hash.indexOf('practiceAssistant') > 0) {
            window.location.href = 'https://www.wuhousmartedu.com/#/resource/practiceAssistant?type=laborWeek';
            window.location.reload();
          } else if (location.hash.indexOf('party') > 0) {
            window.location.href = 'https://www.wuhousmartedu.com/#/partyLogin';
            window.location.reload();
          } else if (location.hash.indexOf('Detail') > 0) {
            console.log('id::'+id);
            window.location.href = 'https://www.wuhousmartedu.com/#/Detail?courseId='+id;
            window.location.reload();
          } else if (location.hash.indexOf('resource/school') > 0) {
            window.location.href = 'https://www.wuhousmartedu.com/#/resource/school/index';
            window.location.reload();
          } else if (location.hash.indexOf('resource') > 0) {
            window.location.href = 'https://www.wuhousmartedu.com/#/resource/index';
            window.location.reload();
          }else{
            alert('暂无数据，请联系资源中心工作人员');
            //window.location.reload();
          }
        }
      })
      /* 登录操作*/
      // var datas = {
      //   "username": zh,
      //   "password": md5('111111'),
      //   'tenantId': '501654',
      //   scope: "all",
      //   type: "account",
      //   types: 1
      // }

      // loginByUsername_api(datas).then((res) => {
      //   if (res.error_code === 400) {
      //     uni.showToast({
      //       icon: "none",
      //       title: '账号错误'
      //     })
      //     resolve(false);
      //   }
      //   if (res.error) {
      //     uni.showToast({
      //       icon: "none",
      //       title: error_description
      //     })
      //     resolve(false);
      //   }
      //   if(!!res.access_token){
      //     saveInfo(res);
      //     resolve(true);
      //   }
      // })
    }
  })

}

