<template>
  <div class="layout flex_col">
    <Header class="header"></Header>
    <div class="router_view flex_grow">
      <router-view></router-view>
    </div>
    <Footer class="footer"></Footer>
  </div>
</template>

<script>
import Header from "@/views/resourceCenter/layout/Header";
import Footer from "@/views/resourceCenter/layout/Footer";

export default {
  name: 'Layout',
  components: {
    Footer,
    Header,
  }
}
</script>

<style scoped>
.layout {
  min-height : 100vh;
  /*min-width  : 1200px;*/
}

.router_view {
  /*min-width        : 1200px;*/
  margin           : 0 auto;
  /*background-color : #f9f9f9;*/
  width            : 100%;
}
</style>
