import mineLayout from "@/views/mine/mineLayout";
import Layout from "@/views/layou/Layou";
export default {
    path: '/mine',
    name: 'mine',
    component: Layout,
    redirect: '/mine/student/signUp',
    children: [
        // {
        //     path: '/mine/index/userIndex',
        //     name: '基础信息',
        //     component: mineLayout,
        //     redirect: '/mine/index/userIndex',
        //     children: [
        //         {
        //             path: '/mine/index/userIndex',
        //             name: '基础信息',
        //             component: () => import('@/views/mine/index/userIndex.vue'),
        //         },
        //     ]
        // },
        {
            path: '/mine/student/signUp',
            name: '我的报名课程',
            component: mineLayout,
            redirect: '/mine/student/signUp',
            children: [
                {
                    path: '/mine/student/signUp',
                    name: '我的报名课程',
                    component: () => import('@/views/mine/student/signUp.vue'),
                },
            ]
        },
        {
            path: '/mine/student/collect',
            name: '收藏',
            component: mineLayout,
            redirect: '/mine/student/collect',
            children: [
                {
                    path: '/mine/student/collect',
                    name: '收藏',
                    component: () => import('@/views/mine/student/collect.vue'),
                },
            ]
        },

    ],
}
