import '@/assets/style/global.css';

import Vue from 'vue';
import eventBus from '@/utils/_eventBus';
import i18n from '@/locales/i18n';
import 'assets/icons';
import 'animate.css';
// import 'lib-flexible/flexible.js';
import { isMobile } from '@/utils/utils';
import App from './App.vue';
import router from './router';
import store from './store/info';
// import vueConfig from '../vue.config'
// import '@/assets/style/black-element-ui.styl';
// import { MessageBox,Message } from 'element-ui'
import './assets/style/black-element-ui.styl';
// import './assets/style/var.styl';
import scroll from 'vue-seamless-scroll';

/*element-ui*/
import ElementUI from 'element-ui';

import('element-ui/lib/theme-chalk/index.css');
import('./assets/css/public.css');
import('@/assets/pusher/icons');
import('@/assets/pusher/style/black-element-ui.styl');

/*富文本编辑器*/
import('@wangeditor/editor/dist/css/style.css');
/*vue-video-player*/
import VideoPlayer from 'vue-video-player';
import 'vue-video-player/src/custom-theme.css';
import 'videojs-contrib-hls';

import global_ from '../vue.config.js.bak';

import * as at from 'array.prototype.at';
Vue.prototype.GLOBAL = global_;
/* 解决低版本浏览器不支持at或者all方法 */
at.shim();
Vue.use(VideoPlayer);
Vue.use(ElementUI);
Vue.use(scroll);
Vue.config.productionTip = false;
// document.title = i18n.t('title.player');

/**
 *  重写ElementUI的Message
 */
const showMessage = Symbol('showMessage');

class DonMessage {
  success(options, single = true) {
    this[showMessage]('success', options, single);
  }

  warning(options, single = true) {
    this[showMessage]('warning', options, single);
  }

  info(options, single = true) {
    this[showMessage]('info', options, single);
  }

  error(options, single = true) {
    this[showMessage]('error', options, single);
  }

  [showMessage](type, options) {
    ElementUI.Message[type](options);
  }
}

Vue.prototype.$alert = ElementUI.MessageBox.alert;
Vue.prototype.$message = new DonMessage();
Vue.prototype.$eventBus = eventBus;
Vue.prototype.$isMobile = isMobile();

// Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
