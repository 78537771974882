<template>
  <div class="mine">
    <!-- <LeftMenu /> -->
    <div class="main">
      <div style="margin-bottom: 20px;color: #a8a8a8;font-size: 14px;">首页 > 个人中心</div>
      <Header/>
      <div>
        <el-menu
            class="tabbar"
            @open="handleOpen"
            @close="handleClose"
            background-color="#fff"
            text-color="#333"
            active-text-color="#fff"
            router
            :default-active="this.$route.path"
        >
          <el-menu-item
              :index="item.path"
              v-for="(item, i) in navArr"
              :key="i"
          >
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
          <!--          <el-menu-item index="2">-->
          <!--            <span slot="title">我的收藏</span>-->
          <!--          </el-menu-item>-->
          <!--          <el-menu-item index="3">-->
          <!--            <span slot="title">报名课程</span>-->
          <!--          </el-menu-item>-->
        </el-menu>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
// import LeftMenu from "@/views/mine/components/leftMenu";
import Header from "@/views/mine/teacher/header";
import router, {allRoutes} from '@/router/index.js'

export default {
  name: "index",
  components: {
    // LeftMenu,
    Header
  },
  data() {
    return {
      navArr: [],
    }
  },

  methods: {
    // eslint-disable-next-line no-unused-vars
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    // eslint-disable-next-line no-unused-vars
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    // 动态路由
    createRouter() {

      // 过滤出当前角色可以访问的路由数组
      const filRouter = (allRoutes) => {
        // let arr = allRoutes
        return allRoutes
      }
      // 调用过滤函数得到可访问路由的数组
      let routerArr = filRouter(allRoutes)
      // 动态添加路由
      router.addRoutes(routerArr)
      // 过滤出需要在左边导航显示的路由
      this.navArr = routerArr[0].children
      // console.log(this.navArr)

    },
  },
  created() {
    this.createRouter()
  },
}
</script>

<style scoped>
* {
  margin: 0 auto;
  padding: 0;
}

.mine {
  width: 90%;
  margin: 0 auto;
  display: flex;
  padding: 40px 0;
  box-sizing: border-box;
}

.main {
  /*width       : 70%;*/
  width: 124rem;
  min-height: 600px;
  /* margin-left : 1.2em; */
}

.tabbar {
  width: 100%;
  display: flex;
  color: #000;
}


::deep .el-menu-item {
  padding: 0 !important;
  margin: 0 10px;
  height: 46px;
}

::deep .el-menu-item.is-active {
  color: #000 !important;
  background-color: #fff !important;
  border-bottom: 2px #3888f7 solid;
  font-weight: bold;
}

::deep .el-menu-item:hover {
  background-color: #fff !important;
}
</style>
